<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="init">营销活动</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">活动页设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
              class
            >
              <el-form-item label="上传banner图：" prop="thumbnail">
                <el-upload
                  :on-change="handlebusinessLicense2"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
                <p>
                  最多只能上传1张,建议上传尺寸750*502px,分辨率72dpi
                  <el-popover placement="right-top" width="400" trigger="hover">
                    <img
                      src="../../assets/activeSet1.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <el-button slot="reference" type="text">查看示例</el-button>
                  </el-popover>
                </p>
              </el-form-item>
              <el-form-item
                label="活动规则："
                class="form-item form-textarea"
                prop="activityRule"
              >
                <!-- <div ref="editor" class="editor" style="width: 100%" /> -->
                <el-input
                  type="textarea"
                  resize="none"
                  v-model="ruleForm.activityRule"
                  class="input_w"
                  :rows="6"
                ></el-input>
                <p>
                  <el-popover placement="right" width="400" trigger="hover">
                    <img
                      src="../../assets/activeSet5.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <el-button slot="reference" type="text">查看示例</el-button>
                  </el-popover>
                </p>
              </el-form-item>
              <el-form-item
                label="温馨提示："
                class="form-item form-textarea"
                prop="activityTips"
              >
                <!-- <div ref="editor2" class="editor" style="width: 100%" /> -->
                <el-input
                  type="textarea"
                  resize="none"
                  v-model="ruleForm.activityTips"
                  class="input_w"
                  :rows="6"
                ></el-input>
                <p>
                  填写邀请码时的温馨提示<el-popover
                    placement="top-start"
                    width="400"
                    trigger="hover"
                  >
                    <img
                      src="../../assets/activeSet3.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <el-button slot="reference" type="text">查看示例</el-button>
                  </el-popover>
                </p>
              </el-form-item>
              <div class="activeSetbtn">
                <el-button class="bgc-bv" @click="doSure">确定</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import E from "wangeditor";

let editor = {};
let editor2 = {};
export default {
  name: "ActivityDetails",
  components: {},
  mixins: [List],
  data() {
    return {
      ruleForm: {
        thumbnail: "",
        activityRule: "",
        activityTips: "",
        thumbnailId: "",
      },
      rules: {
        thumbnail: [
          { required: true, message: "请上传banner图片", trigger: "change" },
        ],
        activityRule: [
          { required: true, message: "请输入活动规则", trigger: "blur" },
        ],
        activityTips: [
          { required: true, message: "请输入温馨提示", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getDetails();
  },
  mounted() {
    // this.editorInit();
    // this.editorInit2();
    // const eWidth = this.$refs.editor.clientWidth;
    // this.eWidth = eWidth;
  },
  computed: {},
  methods: {
    /* 上传banner图 */
    handlebusinessLicense2(res) {
      this.uploadFetch(res.raw);
    },
    uploadFetch(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.thumbnail = ret.data.fileURL || "";
        that.ruleForm.thumbnailId = ret.data.fileKey || "";
      });
    },
    getDetails() {
      this.$post("/actconfig/act-config/query", {}, 3000, true, 2)
        .then((ret) => {
          console.log(ret.data);
          this.ruleForm = {
            ...this.ruleForm,
            ...ret.data,
            thumbnail: ret.data.imgUrl,
            configId: ret.data.configId,
            createId: ret.data.createId,
            thumbnailId: ret.data.imgPath,
            // activityRule: ret.data.activityRule,
            // activityTips: ret.data.activityTips,
            activityRule: ret.data.activityRule.replace(/sunrealbr/g, "\n"),
            activityTips: ret.data.activityTips.replace(/sunrealbr/g, "\n"),
          };
          // editor.txt.html(ret.data.activityRule);
          // editor2.txt.html(ret.data.activityTips);
        })
        .catch((err) => {
          return;
        });
    },
    /* 确定 */
    doSure() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // if (editor.txt.text().trim() == "") {
          //   this.$message.warning("请输入活动规则");
          //   return;
          // }
          // if (editor2.txt.text().trim() == "") {
          //   this.$message.warning("请输入温馨提示");
          //   return;
          // }
          //接口
          let params = {
            imgPath: this.ruleForm.thumbnailId,
            // activityRule: this.ruleForm.activityRule,
            // activityTips: this.ruleForm.activityTips,
          };
          if (this.ruleForm.configId) {
            params.configId = this.ruleForm.configId;
          }
          if (this.ruleForm.createId) {
            params.createId = this.ruleForm.createId;
          }
          this.$post(
            "/actconfig/act-config/modify",
            {
              ...params,
              activityRule: this.ruleForm.activityRule.replace(
                /\n/g,
                "sunrealbr"
              ),
              activityTips: this.ruleForm.activityTips.replace(
                /\n/g,
                "sunrealbr"
              ),
            },
            3000,
            true,
            2
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.getDetails();
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "justify", // 对齐方式
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.ruleForm.activityRule = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      editor.txt.clear();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    editorInit2() {
      editor2 = new E(this.$refs.editor2);
      editor2.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "justify", // 对齐方式
      ];
      editor2.config.zIndex = 100;
      editor2.config.uploadImgShowBase64 = true;
      editor2.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor2.config.pasteFilterStyle = "text";
      editor2.config.pasteIgnoreImg = true;

      editor2.config.onchange = (html) => {
        this.ruleForm.activityTips = html;
      };
      editor2.config.pasteTextHandle = function (content) {
        return content + "";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor2.create();

      const eWidth = this.$refs.editor2.clientWidth;
      this.eWidth = eWidth;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.form-textarea {
  .el-textarea {
    .el-textarea__inner {
      min-height: 13rem !important;
      resize: none;
    }
  }
}
.activeSetbtn {
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;
}
</style>
